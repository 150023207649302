@keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

.loader-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
    opacity: .75;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle-loader {
        font-size: 100px;
        animation: spin-animation 1s infinite linear;
    }
}