.style-info {
    flex-basis: 25%;
    flex-grow:1;
    padding: 20px;

    &--separator {
        margin: 30px auto;
        &::after {
            content:'';
            width: 96%;
            border-top: 1px solid #d8dadf;
            display: block;
            color: #26262c;
        }
    }
    &--description-title {
        font-family: "Slate-std-med";
        text-transform: uppercase;
    }
    &--description {
        color: #6d7283;
    }
    &--image-holder {
        text-align:center;
        display: flex;
        width:100%;
        position: relative;
        img {
            max-width: 400px;
            width: 100%;
        }

        &--view {
            flex-grow: 0;
            flex-basis: 50%;
        }
    }
    &--reversible-button {
        width: 90px;
        height: 120px;
        top: 0;
        left: 0;
        box-shadow: 0 -4px 20px rgba(215,216,219,.5);
        border: 1px solid #d8dadf;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
        flex-shrink: 1;

        span {
            font-size: 12px;
            font-family: "Slate Std",serif;
        }
    }
}