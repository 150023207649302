input, select {
    border-radius: 4px;
    border: 1px solid #cccccc;
    padding: 10px 12px;
    font-family: 'Slate Std Regular';
    font-size: 14px;
    width: 100%;

    &.part-small {
        width: 70px;
    }
    &.part-xsmall {
        width: 50px;
    }
}

input[type=number] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        opacity: 1;
    }
}