.footer {
  background-color: #252525;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  font-family: "Forza-bold";
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px;

  .big-day {
    font-size: 26px;
    padding: 0 0px 0 2px !important;
    font-family: "Forza-bold";
  }

  &--left {
    flex-basis: 40%;
  }
  &--section {
    border-left: 1px solid #cfcfcf;
    flex-grow: 1;
    padding: 0 30px;

    button {
      font-family: "Forza-bold";
      font-size: 18px;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      border: 1px solid white;
      color: white;
      background-color: transparent;

      &:hover {
        background-color: black;
      }

      svg {
        height: 30px;
      }
    }

    &:first-child {
      border-left: none;
    }
  }

  

  &--section {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    & > div {
      padding: 0 10px;
    }
  }

  &--subtitle {
    font-family: "Slate Std Regular";
    color: #cfcfcf;
    font-size: 15px;
  }

  &--rush {
    display: flex;
    align-items: center;

    &Title {
      color: red;
      margin-right: 3px;
    }
    &Icon {
      font-size: 18px;
      cursor: pointer;
    }
  }

  .tooltip {
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    position: relative;
    font-weight: normal;
    font-family: "Slate Std";
    max-width: 180px;
    visibility: hidden;
    border-radius: 8px;
    color: black;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
    text-transform: none;

    &[data-show="true"] {
      visibility: visible;
    }

    &[to-red="true"] {
      background: tomato;
      color: white;
    }

    .arrow,
    .arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      transition: top 0.3s ease-in, bottom 0.3s ease-in, left 0.3s ease-in,
        right 0.3s ease-in;
    }

    .arrow {
      visibility: hidden;
    }

    .arrow::before {
      visibility: hidden;
      content: "";
      transform: rotate(45deg);
    }

    &[data-popper-placement^="top"] > .arrow {
      bottom: -4px;
    }

    &[data-popper-placement^="bottom"] > .arrow {
      top: -4px;
    }

    &[data-popper-placement^="left"] > .arrow {
      right: -4px;
    }

    &[data-popper-placement^="right"] > .arrow {
      left: -4px;
    }

    .arrow[data-hide="true"]::before {
      visibility: hidden !important;
    }

    &[data-show] > .arrow::before {
      visibility: visible;
    }
  }
}
