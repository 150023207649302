@font-face {
  font-family: "Forza";
  src: url("./Assets/Fonts/Forza-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Forza-light";
  src: url("./Assets/Fonts/Forza-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Forza-bold";
  src: url("./Assets/Fonts/Forza-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Slate Std";
  src: url("./Assets/Fonts/slate-pro-light-417.otf") format("truetype");
}

@font-face {
  font-family: "Slate-std-med";
  src: url("./Assets/Fonts/slate-std-medium.otf") format("truetype");
}

@font-face {
  font-family: "Slate Std Regular";
  src: url("./Assets/Fonts/slate-pro-book.otf") format("truetype");
}

@font-face {
  font-family: "Slate Std Light Italic";
  src: url("./Assets/Fonts/slate-pro-light-italic.otf") format("truetype");
}

@font-face {
  font-family: "Slate Std Bold";
  src: url("./Assets/Fonts/slate-std-bold.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5715;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}