.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
}
html {
  font-size: 14px;
}

h3 {
  font-family: 'Forza-bold';
  font-size: 24px;
}



button.footer-red {
  border: 1px solid #ed2024 !important;
  background-color: #ed2024 !important;
}