.body {
    flex-grow: 1;
    overflow-y: auto;
}
.page {
    display: flex;
    flex-wrap: nowrap;
    padding: 20px 0;
    .left {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #cccccc;
        .sub-previews {
            display: flex;
            flex-wrap: nowrap;
            padding: 0 20px;
            .sub-preview {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                background-color: #E9EEEE;
                margin: 0 10px;
                border-radius: 8px;
                border: 1px solid #DDDDDD;
                text-transform: uppercase;
                padding-bottom: 10px;
                max-width: 125px;

                &-status {
                    display: none;

                    &--inactive {
                        display: block;
                    }
                }

                &--active {
                    cursor: unset;
                    background-color: white;
                    border-color: rgb(0, 200, 0);
                    .sub-preview-status--active {
                        display: block;
                    }
                    .sub-preview-status--inactive {
                        display: none;
                    }
                }
            }
            img {
                width: 100%;
            }
        }

        .error-block {
            font-family: "Slate Std";
            background-color: #f7f7f7;
            padding: 1em 0.75em;
            border-radius: 5px;
            margin: 1em;
            &--top {
                display: flex;
            }
            &--left {
                flex-basis: 50px;
                img {
                    width: 25px;
                }
            }
            &--right {
                flex-grow: 1;
            }
            &--title {
                color: #D47237;
                font-family: "Slate Std Bold", Helvetica, Arial;
                font-size: 18px;
            }
            &--desc {
                
            }
            &--message {
                color: #6d7283;
                background-color: #FFFFFF;
                border-radius: 5px;
                padding: 1em;
                margin-top: 0.5em;
                p {
                    margin: 0;
                }
                b {
                    font-weight: 700;
                    color: #26262c;
                }
                span {
                    font-weight: 700;
                    color: red;
                }
            }
        }
    }
    .right {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        &-holder {
            width: 100%;
            max-width: 800px;
            padding: 0 20px;
        }

        .order-summary {
            color: #B0B0B0;
            font-size: 24.5px;
            font-family: "Slate-std-med";
            margin-bottom: 25px;
            &-dot {
                font-size: 28px;
            }
        }

        .size-summary {
            display: flex;
            background-color: #F2F2F2;
            padding: 10px;
            margin: 10px 0;
            font-size: 14px;
            align-items: baseline;

            &--title {
                color: #6d7283;
                font-family: "Slate Std","Helvetica","Arial";
                text-transform: uppercase;
            }
            &--title, &--sum {
                padding: 5px;
            }
            &--sum {
                flex-grow: 1;
            }
            &--item {
                padding: 5px;

                b {
                    font-family: "Slate Std Bold","Helvetica","Arial";
                }
            }
        }
        .roster--section {
            margin-bottom: 20px;
        }
        .roster-holder {
            border: 1px solid #cccccc;
            max-height: 300px;
            overflow-y: auto;
            table {
                width: 100%;
                border-collapse: collapse;

                th {
                    padding: 10px 10px;
                    text-align:left;
                    font-family: "Slate Std",serif;
                    color: #b0b0b0;
                    font-weight: normal;
                }
                td {
                    padding: 10px 10px;
                }
                tr {
                    border-top: 1px solid #ececec;
                }
            }
        }

        .style-total {
            font-weight: bold;
            text-align: right;
            margin: 20px 0;
        }
    }
}