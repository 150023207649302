.header {
    height: 60px;
    background-color: white;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    padding-top: 0;
    .nav {
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 1;

        &> .button {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 54px;
            border: none;
            background-color: transparent;
            font-family: "Forza";
            font-weight: 500;
            font-size: 17px;
            cursor: pointer;
            color: #949494;
            position: relative;
            flex-grow: 1;

            &.is-active {
                color: #000;

                &::after {
                    position: absolute;
                    content: '';
                    display: block;
                    background: #6a6a6a;
                    height: 7px;
                    border-radius: 10px;
                    bottom: -3px;
                    width: 100%;
                }
            }
        }
    }

}