.style-header {
    &--title {
        font-family: "Forza-bold";
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        color: #26262c;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        img {
            max-height: 30px;
            max-width: 120px;
        }
    }
    &--subtitle {
        font-family: "Forza-bold";
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 22px;
        color: #26262c;
    }

    &--homeaway-note {
        font-size: 16px;
        color: #6d7283;
        font-family: Slate Std,serif;
        border-radius: 24px;
        background: #eceded;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        font-weight: normal;
        white-space: nowrap;

        svg {
            margin-left: 5px;
        }
    }
}