@import '../../shared/styles/form-controls.scss';

.body {
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    flex-grow:1;
    &.roster {
        flex-wrap: nowrap;
        width: 100%;
    }
    .left {
        flex-basis: 40%;
        display: flex;
    }
    .right {
        flex-grow: 1;
        padding: 0 20px;
        border-left: 1px solid #cccccc;
    }
}

.roster-table {
    width: 100%;
    border-collapse: collapse;
    &--section-header {
        color: #6d7283;
        padding: 10px;

    }
    &--col-header {
        color: #6d7283;
        padding: 10px;
        font-family:"Slate Std Regular";
        font-size:16px;
        text-align:start;
        font-weight: 400;
    }
    &--row-spacer {
        padding: 10px;
    }

    tr {
        &.invalid {
            background-color:#ed2024;
        }

        td {
            padding: 4px;
        }
    }
    .roster-button {
        border:0;
        background-color: #F2F2F2;
        border-radius: 4px;
        padding: 6px 6px;
        display: flex;
        cursor: pointer;
    }
    .delete-button {
        color: #ed2024;
    }
}