@import '../../shared/styles/form-controls.scss';

.body {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    flex-grow: 1;
}
.item-row {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    border-bottom: 1px solid #cccccc;
    padding: 20px 0;
    .left {
        width: 30%;
        flex-shrink: 0;
    }
    .right {
        flex-grow: 1;
        padding: 0 20px;
        border-left: 1px solid #cccccc;
    }

    .gender-section {

        .rosters {
            display: flex;
            flex-wrap: wrap;

            .roster-block {
                padding: 4px 12px;

                input {
                    width: 70px;
                }

                .roster-title {
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 6px;
                }

                .size-block {
                    font-family: 'Forza';
                    font-size: 24px;
                    font-weight: 900;
                }
                .price-block {
                    font-family: 'Slate Std Regular';
                    text-align: right;
                    flex-grow: 1;
                }
            }
        }
    }
}