@keyframes gradient {
    0% {
      transform: translateZ(1px) translateX(-10%) scaleX(0%) scaleY(100%);
      filter: hue-rotate(0deg);
    }
    25% {
      transform: translateZ(1px) translateX(25%) scaleX(25%) scaleY(100%);
      filter: hue-rotate(-90deg);
    }
    50% {
      transform: translateZ(1px) translateX(50%) scaleX(30%) scaleY(100%);
      filter: hue-rotate(-120deg);
    }
    75% {
      transform: translateZ(1px) translateX(75%) scaleX(25%) scaleY(100%);
      filter: hue-rotate(-175deg);
    }
    100% {
      transform: translateZ(1px) translateX(100%) scaleX(0%) scaleY(100%);
      filter: hue-rotate(-230deg);
    }
  }
  
  .threekitLoader {
    overflow: hidden;
    position: relative;
    background-color: #db0608;
    height: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
  
    // Due to some of the bigger threekit loads "locking" the screen, we have to create the animation using
    // composited GPU rendering which only leaves opacity, filter, and transform as valid options.
    // Using translates, scales, and hus-rotations we can create the illusion of a linear-gradient
    // while still keeping the animation moving during even threekits most locking loads.
    // translateZ double down to force GPU compositing in some renderers.
    animation: gradient 1.75s linear infinite;
    transform: translateZ(1px) translateX(0%) scaleX(0%) scaleY(0%);
    filter: hue-rotate(0deg);
    transition: 0.5s all linear;
    transform-origin: 0 0;
    opacity: 1;
  
    &.show {
      opacity: 1;
    }
    &.exit {
      opacity: 0;
    }
  }

  .loaderOverlay {
    background-color: rgba(0,0,0,.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    .messageBox {
      background-color: white;
      border-radius: 8px;
      width: 500px;
      max-width: 95%;
      padding: 40px;
    }
    .message {
      font-family: "Slate Std Bold", Helvetica, Arial;
      font-size: 24px;
      text-align: center;
    }
  }
  